import { ref } from 'vue'
import { defineStore } from 'pinia'
import axiosAuth from '@/utils/AxiosAuth'
import { useRuntimeStore } from '@/stores/runtimeStore'
import {notify} from "notiwind";


export const useAdmin = defineStore('adminStore', () => {
  const adminSettings = ref({
    recurring_services_enabled: false,
    convenience_fee_enabled: false,
    convenience_fee: 0,
    update_value_of: '',
    price_lock_in: 0,
    product_tax_rate: 0,
    service_tax_rate: 0,
    quickbooks_tax_code: {
      id: '',
      name: '',
      description: '',
      rates: [],
      total_rate: 0
    },
    company_name: '',
    company_id: '',
    company: {
      name: '',
      email: ''
    },
    quickbooks_logged_in: false,
    quickbooks_token_active: false,
    quickbooks_user: false
  })

  const attachments = ref({
    id:'',
    url:''
})

  const portalCustomization = ref({})
  const IframeCustomization = ref({})
  const IframeFields = ref({})
  const IframeCustomFields = ref({})

  const skippedQbSignIn = ref(useCookie('skippedQbSignIn').value)
  console.log("..............skippedQbSignIn.........", skippedQbSignIn);
  const showRecurringServices = ref(false)
  // const useRecurringServices = ref(useToggle(showRecurringServices))

  const handleRecurringServices = () => {
    // useRecurringServices.value.toggle()

    // adminSettings.value.recurring_services_enabled =
      // useRecurringServices.value.showing
  }

  const skipQbAuth = () => {
    skippedQbSignIn.value = true
    localStorage.setItem('skippedQbSignIn', skippedQbSignIn.value)
  }

  const clearQbSkip = () => {
    skippedQbSignIn.value = false
    localStorage.removeItem('skippedQbSignIn')
  }

  const getAdminSettings = async () => {
    const root = `${useRuntimeStore().$state.apiRoot}/admin`
    await axiosAuth
      .get(`${root}/global_settings`)
      .then((res) => {
        adminSettings.value = res.data
      })
      .catch((err) => {
        console.log('getAdminSettings', err)
      })
  }

  const setAdminSettings = async (data) => {
    console.log('setAdminSettings', data)
    const root = `${useRuntimeStore().$state.apiRoot}/admin`
    await axiosAuth
      .post(`${root}/global_settings`, data)
      .then((res) => {
        adminSettings.value = res.data
      })
      .catch((err) => {
       })
  }

  const generateLead = async (data,company_id) => {
    const root = `${useRuntimeStore().$state.apiRoot}/notifications`
    let url =''
    if (company_id){
      url =`${root}/leads?company_id=${company_id}`
    }
    else{
      url = `${root}/leads`
    }
    await axiosAuth
      .post(url, data)
      .then((res) => {

      })
      .catch((err) => {
       })
  }

  const getPortalCustomization = async () => {
    const root = `${useRuntimeStore().$state.apiRoot}/admin`
    await axiosAuth
      .get(`${root}/portal/customizations`)
      .then((res) => {
        portalCustomization.value = res.data
      })
      .catch((err) => {
        console.log(err)
       })
  }
  const createIframeCustomization = async () => {
    const root = `${useRuntimeStore().$state.apiRoot}/admin`
    await axiosAuth
      .post(`${root}/iframe/create_customization`, {})
      .then((res) => {
        IframeCustomization.value = res.data
      })
      .catch((err) => {
        console.log(err)
       })
  }
  const getIframeCustomization = async (company_id) => {
    const root = `${useRuntimeStore().$state.apiRoot}/admin`
    let url = ''
    if (company_id !== undefined){
      url =`${root}/iframe/customizations?company_id=${company_id}`
    }
    else{
      url =`${root}/iframe/customizations`
    }
    await axiosAuth
        .get(url)
        .then((res) => {
          IframeCustomization.value = res.data
        })
        .catch((err) => {
          console.log(err)
        })
  }
  const getIframeFields = async (company_id = null) => {
    const root = `${useRuntimeStore().$state.apiRoot}/admin`
    let url = ''
    if (company_id !== null){
      url =`${root}/iframe/fields?company_id=${company_id}`
    }
    else{
      url =`${root}/iframe/fields`
    }
    await axiosAuth
        .get(url)
        .then((res) => {
          IframeFields.value = res.data
        })
        .catch((err) => {
          console.log(err)
        })
  }

  const getIframeFieldsCustom = async (company_id = null) => {
    const root = `${useRuntimeStore().$state.apiRoot}/admin`
    await axiosAuth
        .get(`${root}/iframe/custom_fields`)
        .then((res) => {
          IframeCustomFields.value = res.data
        })
        .catch((err) => {
          console.log(err)
        })
  }
  const createOrUpdateIframeFieldsCustom = async (field_id,is_included) => {
    const root = `${useRuntimeStore().$state.apiRoot}/admin`
    await axiosAuth
        .post(`${root}/iframe/custom_field`,{
          field_id: field_id,
          is_included: is_included
        })
        .then((res) => {
          notify(
              {
                group: 'success',
                title: 'Preference Updated',
                text: 'Field Preferences Is Updated Successfully'
              },
              2000
          )
        })
        .catch((err) => {
          notify(
              {
                group: 'error',
                title: 'Preference Update failed',
                text: 'Apply Customization first to add new fields'
              },
              2000
          )
        })
  }
  const updateIframeCustomization = async () => {
    const root = `${useRuntimeStore().$state.apiRoot}/admin`
    await axiosAuth
        .put(`${root}/iframe/customization/${IframeCustomization.value.id}`, IframeCustomization.value)
        .then((res) => {
          IframeCustomization.value = res.data
        })
        .catch((err) => {
          console.log(err)
        })
  }


  const updatePortalCustomization = async (id,data) => {
    console.log('setAdminSettings', data)
    const root = `${useRuntimeStore().$state.apiRoot}/admin`
    await axiosAuth
      .put(`${root}/portal/customization/${id}`, data)
      .then((res) => {
        portalCustomization.value = res.data
      })
      .catch((err) => {
        console.log(err)
       })
  }

  const createPortalCustomization = async (data) => {
    console.log('setAdminSettings', data)
    const root = `${useRuntimeStore().$state.apiRoot}/admin`
    await axiosAuth
      .post(`${root}/portal/customizations`, data)
      .then((res) => {
        portalCustomization.value = res.data
      })
      .catch((err) => {
        console.log(err)
       })
  }
  const values = {
    adminSettings,
    // useRecurringServices,
    showRecurringServices,
    skippedQbSignIn,
    portalCustomization,
    IframeCustomization,
    IframeFields,
    IframeCustomFields
  }

  const actions = {
    getAdminSettings,
    setAdminSettings,
    attachments,
    handleRecurringServices,
    skipQbAuth,
    clearQbSkip,
    getPortalCustomization,
    updatePortalCustomization,
    createPortalCustomization,
    getIframeCustomization,
    updateIframeCustomization,
    getIframeFields,
    getIframeFieldsCustom,
    createOrUpdateIframeFieldsCustom,
    generateLead,
    createIframeCustomization
  }

  return {
    ...values,
    ...actions
  }
})
